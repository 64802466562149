

export const getSvgs = (color) => ({
  icons: {
    eye: `
      <svg width="40" height="28" viewBox="0 0 40 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20 8.18182C18.5534 8.18182 17.166 8.75649 16.1431 9.77942C15.1201 10.8023 14.5455 12.1897 14.5455 13.6364C14.5455 15.083 15.1201 16.4704 16.1431 17.4933C17.166 18.5162 18.5534 19.0909 20 19.0909C21.4466 19.0909 22.834 18.5162 23.8569 17.4933C24.8799 16.4704 25.4545 15.083 25.4545 13.6364C25.4545 12.1897 24.8799 10.8023 23.8569 9.77942C22.834 8.75649 21.4466 8.18182 20 8.18182ZM20 22.7273C17.5889 22.7273 15.2766 21.7695 13.5718 20.0646C11.8669 18.3597 10.9091 16.0474 10.9091 13.6364C10.9091 11.2253 11.8669 8.913 13.5718 7.20812C15.2766 5.50324 17.5889 4.54545 20 4.54545C22.4111 4.54545 24.7234 5.50324 26.4282 7.20812C28.1331 8.913 29.0909 11.2253 29.0909 13.6364C29.0909 16.0474 28.1331 18.3597 26.4282 20.0646C24.7234 21.7695 22.4111 22.7273 20 22.7273ZM20 0C10.9091 0 3.14545 5.65455 0 13.6364C3.14545 21.6182 10.9091 27.2727 20 27.2727C29.0909 27.2727 36.8545 21.6182 40 13.6364C36.8545 5.65455 29.0909 0 20 0Z" fill="${color}"/>
      </svg>
    `,
    new: `
      <svg width="40" height="32" viewBox="0 0 40 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M36 0C38.22 0 40 1.78 40 4V28C40 30.22 38.22 32 36 32H4C1.78 32 0 30.22 0 28V4C0 1.78 1.78 0 4 0H36ZM13 22V10H10.5V17L5.5 10H3V22H5.5V15L10.6 22H13ZM23 12.52V10H15V22H23V19.5H18V17.28H23V14.76H18V12.52H23ZM37 20V10H34.5V19H32.26V12H29.76V19H27.5V10H25V20C25 20.5304 25.2107 21.0391 25.5858 21.4142C25.9609 21.7893 26.4696 22 27 22H35C35.5304 22 36.0391 21.7893 36.4142 21.4142C36.7893 21.0391 37 20.5304 37 20Z" fill="${color}"/>
      </svg>
    `,
    archive: `
      <svg width="40" height="29" viewBox="0 0 40 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M37.1429 0H2.85714C2.09938 0 1.37266 0.301019 0.836838 0.836838C0.301019 1.37266 0 2.09938 0 2.85714V7.14286C0 7.90062 0.301019 8.62734 0.836838 9.16316C1.37266 9.69898 2.09938 10 2.85714 10V25.7143C2.85714 26.472 3.15816 27.1988 3.69398 27.7346C4.2298 28.2704 4.95652 28.5714 5.71429 28.5714H34.2857C35.0435 28.5714 35.7702 28.2704 36.306 27.7346C36.8418 27.1988 37.1429 26.472 37.1429 25.7143V10C37.9006 10 38.6273 9.69898 39.1632 9.16316C39.699 8.62734 40 7.90062 40 7.14286V2.85714C40 2.09938 39.699 1.37266 39.1632 0.836838C38.6273 0.301019 37.9006 0 37.1429 0ZM24.2857 17.1429H15.7143C15.3354 17.1429 14.972 16.9923 14.7041 16.7244C14.4362 16.4565 14.2857 16.0932 14.2857 15.7143C14.2857 15.3354 14.4362 14.972 14.7041 14.7041C14.972 14.4362 15.3354 14.2857 15.7143 14.2857H24.2857C24.6646 14.2857 25.028 14.4362 25.2959 14.7041C25.5638 14.972 25.7143 15.3354 25.7143 15.7143C25.7143 16.0932 25.5638 16.4565 25.2959 16.7244C25.028 16.9923 24.6646 17.1429 24.2857 17.1429ZM37.1429 7.14286H2.85714V2.85714H37.1429V7.14286Z" fill="${color}"/>
      </svg>
    `,
    recomended: `
      <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 2H32M5.15789 2V20.3337C5.15789 24.2158 5.83684 25.4843 8.99158 27.4812L13.7505 30.4948C15.3342 31.4983 16.1253 32 17 32C17.8747 32 18.6658 31.4983 20.2495 30.4948L25.01 27.4812C28.1616 25.4826 28.8421 24.2141 28.8421 20.3337V2M20.9474 18.6685H13.0526M20.9474 10.3343H13.0526" stroke="${color}" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>`
  },
  media: {
    web: `
      <svg id="Website" xmlns="http://www.w3.org/2000/svg" width="54.562" height="54.561" viewBox="0 0 54.562 54.561">
        <g id="Group_49" data-name="Group 49" transform="translate(0 0)">
          <path id="Path_394" data-name="Path 394" d="M27.281,0A27.283,27.283,0,1,1,7.991,7.991,27.152,27.152,0,0,1,27.281,0ZM45.075,9.5a25.181,25.181,0,1,0,7.356,17.779A25.132,25.132,0,0,0,45.075,9.5Z" transform="translate(0)" fill="${color}"/>
          <path id="Path_395" data-name="Path 395" d="M35.406,52.781A16.847,16.847,0,1,1,52.253,35.934,16.866,16.866,0,0,1,35.406,52.781Zm0-31.288a14.44,14.44,0,1,0,14.44,14.44A14.456,14.456,0,0,0,35.406,21.494Z" transform="translate(-8.331 -8.568)" fill="${color}"/>
          <rect id="Rectangle_31" data-name="Rectangle 31" width="31.287" height="1.203" transform="translate(10.83 26.163)" fill="${color}"/>
          <rect id="Rectangle_32" data-name="Rectangle 32" width="1.203" height="31.287" transform="translate(26.474 11.723)" fill="${color}"/>
          <path id="Path_396" data-name="Path 396" d="M35.171,52.384C28.3,35.57,35.129,22.622,35.2,22.492l1.058.573c-.067.125-6.619,12.6.028,28.863Z" transform="translate(-14.413 -10.096)" fill="${color}"/>
          <path id="Path_397" data-name="Path 397" d="M59.683,52.384l-1.114-.456c6.658-16.291.1-28.739.029-28.862l1.058-.574C59.725,22.622,66.554,35.57,59.683,52.384Z" transform="translate(-26.29 -10.096)" fill="${color}"/>
          <path id="Path_398" data-name="Path 398" d="M36.662,63.217a33.82,33.82,0,0,1-14.7-3.09l.572-1.059c.126.067,12.629,6.62,28.924-.027l.455,1.114A40.2,40.2,0,0,1,36.662,63.217Z" transform="translate(-9.86 -26.502)" fill="${color}"/>
          <path id="Path_399" data-name="Path 399" d="M51.462,36.757c-16.3-6.646-28.8-.094-28.924-.027l-.572-1.059c.129-.069,13.1-6.9,29.95-.028Z" transform="translate(-9.86 -14.625)" fill="${color}"/>
        </g>
      </svg>`,
    facebook: `
      <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M31.9209 16.4724C31.9209 7.64041 24.7706 0.472412 15.9604 0.472412C7.15028 0.472412 0 7.64041 0 16.4724C0 24.2164 5.49039 30.6644 12.7684 32.1524V21.2724H9.57627V16.4724H12.7684V12.4724C12.7684 9.38441 15.2741 6.87241 18.3545 6.87241H22.3446V11.6724H19.1525C18.2747 11.6724 17.5565 12.3924 17.5565 13.2724V16.4724H22.3446V21.2724H17.5565V32.3924C25.6165 31.5924 31.9209 24.7764 31.9209 16.4724Z" fill="${color}"/>
      </svg>
      `,
    instegram: `
      <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.48521 0.472412H22.892C27.9993 0.472412 32.149 4.63241 32.149 9.75241V23.1924C32.149 25.6536 31.1737 28.014 29.4377 29.7544C27.7017 31.4947 25.3471 32.4724 22.892 32.4724H9.48521C4.37786 32.4724 0.228149 28.3124 0.228149 23.1924V9.75241C0.228149 7.2912 1.20344 4.9308 2.93948 3.19046C4.67551 1.45012 7.03008 0.472412 9.48521 0.472412ZM9.166 3.67241C7.64213 3.67241 6.18067 4.27927 5.10313 5.35948C4.02559 6.43969 3.42024 7.90477 3.42024 9.43241V23.5124C3.42024 26.6964 5.98987 29.2724 9.166 29.2724H23.2112C24.7351 29.2724 26.1965 28.6656 27.2741 27.5853C28.3516 26.5051 28.9569 25.0401 28.9569 23.5124V9.43241C28.9569 6.24841 26.3873 3.67241 23.2112 3.67241H9.166ZM24.5678 6.07241C25.0969 6.07241 25.6044 6.28313 25.9785 6.6582C26.3527 7.03327 26.5629 7.54198 26.5629 8.07241C26.5629 8.60285 26.3527 9.11155 25.9785 9.48663C25.6044 9.8617 25.0969 10.0724 24.5678 10.0724C24.0387 10.0724 23.5312 9.8617 23.1571 9.48663C22.783 9.11155 22.5728 8.60285 22.5728 8.07241C22.5728 7.54198 22.783 7.03327 23.1571 6.6582C23.5312 6.28313 24.0387 6.07241 24.5678 6.07241ZM16.1886 8.47241C18.3051 8.47241 20.3349 9.31527 21.8315 10.8156C23.328 12.3158 24.1688 14.3507 24.1688 16.4724C24.1688 18.5941 23.328 20.629 21.8315 22.1293C20.3349 23.6296 18.3051 24.4724 16.1886 24.4724C14.0721 24.4724 12.0423 23.6296 10.5457 22.1293C9.04914 20.629 8.20837 18.5941 8.20837 16.4724C8.20837 14.3507 9.04914 12.3158 10.5457 10.8156C12.0423 9.31527 14.0721 8.47241 16.1886 8.47241ZM16.1886 11.6724C14.9187 11.6724 13.7008 12.1781 12.8029 13.0783C11.9049 13.9785 11.4005 15.1994 11.4005 16.4724C11.4005 17.7455 11.9049 18.9664 12.8029 19.8665C13.7008 20.7667 14.9187 21.2724 16.1886 21.2724C17.4585 21.2724 18.6764 20.7667 19.5743 19.8665C20.4723 18.9664 20.9767 17.7455 20.9767 16.4724C20.9767 15.1994 20.4723 13.9785 19.5743 13.0783C18.6764 12.1781 17.4585 11.6724 16.1886 11.6724Z" fill="${color}"/>
      </svg>
    `,
    tiktok: `
      <svg width="26" height="30" viewBox="0 0 26 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.0967 5.012C19.0175 3.7634 18.4227 2.15989 18.4232 0.5H13.5446V20.34C13.507 21.4136 13.0597 22.4306 12.297 23.1767C11.5343 23.9228 10.5156 24.3398 9.4555 24.34C7.21359 24.34 5.35059 22.484 5.35059 20.18C5.35059 17.428 7.97142 15.364 10.6712 16.212V11.156C5.22428 10.42 0.456268 14.708 0.456268 20.18C0.456268 25.508 4.81379 29.3 9.43971 29.3C14.3972 29.3 18.4232 25.22 18.4232 20.18V10.116C20.4014 11.5558 22.7765 12.3282 25.212 12.324V7.38C25.212 7.38 22.2439 7.524 20.0967 5.012Z" fill="${color}"/>
      </svg>
    `,
    youtube: `
      <svg width="33" height="23" viewBox="0 0 33 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.1661 16.5417L21.501 11.7417L13.1661 6.94175V16.5417ZM31.7309 4.01375C31.9397 4.76575 32.0842 5.77375 32.1806 7.05375C32.293 8.33375 32.3412 9.43775 32.3412 10.3977L32.4375 11.7417C32.4375 15.2457 32.1806 17.8217 31.7309 19.4697C31.3294 20.9097 30.398 21.8377 28.9526 22.2377C28.1978 22.4457 26.8167 22.5898 24.6968 22.6858C22.6091 22.7978 20.698 22.8458 18.9315 22.8458L16.378 22.9417C9.64905 22.9417 5.45751 22.6857 3.80337 22.2377C2.35801 21.8377 1.42656 20.9097 1.02507 19.4697C0.816297 18.7177 0.671761 17.7097 0.575404 16.4297C0.462987 15.1497 0.414808 14.0457 0.414808 13.0857L0.318451 11.7417C0.318451 8.23775 0.575404 5.66175 1.02507 4.01375C1.42656 2.57375 2.35801 1.64575 3.80337 1.24575C4.55817 1.03775 5.93929 0.893748 8.05915 0.797748C10.1469 0.685748 12.058 0.637748 13.8245 0.637748L16.378 0.541748C23.1069 0.541748 27.2985 0.797748 28.9526 1.24575C30.398 1.64575 31.3294 2.57375 31.7309 4.01375Z" fill="${color}"/>
      </svg>`,
    twitter: `
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="16" cy="16" r="16" fill="${color}"/>
        <path d="M20.4312 8H23.037L17.3156 14.3653L24 23H18.7544L14.6475 17.7528L9.94568 23H7.33989L13.4012 16.1919L7 8H12.3759L16.0863 12.7934L20.4312 8ZM19.5192 21.5055H20.9637L11.6168 9.43911H10.0646L19.5192 21.5055Z" fill="#68D5FE"/>
      </svg>`,
    linkedin: `
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="16" cy="16" r="16" fill="#262626"/>
        <circle cx="16" cy="16" r="16" fill="black" fill-opacity="0.2"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7 15.1888V24H11.1966V11.9946H7V15.1888ZM24.8975 15.6853C24.6224 13.4083 23.5814 11.9946 20.5121 11.9946C18.7105 11.9946 17.4969 12.6583 16.9898 13.6187H16.9359V11.9946H13.5915V24H17.0923V18.0486C17.0923 16.4784 17.3998 14.9676 19.3254 14.9676C21.2511 14.9676 21.4022 16.759 21.4022 18.1565V24H25V17.4065C25 16.786 24.9676 16.214 24.9029 15.6853H24.8975ZM9.09829 6C7.93857 6 7 6.93885 7 8.09892C7 9.25899 7.93857 10.1978 9.09829 10.1978C10.258 10.1978 11.1966 9.25899 11.1966 8.09892C11.1966 6.93885 10.258 6 9.09829 6Z" fill="#68D5FE"/>
      </svg>`,
  },
});